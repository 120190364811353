import React from "react"
import { graphql, Link } from "gatsby"
import VideoList from "../../../components/VideoList"

const VieuxFilmsGratuits = props => {
    const url = "https://cinetimes.org/collections/vieux-films-gratuits/"
    const edges = props.data.allVideo.edges
    const title = "Vieux films gratuits 🇫🇷 " 
    const description = `Sélection de ${edges.length} vieux films en Français, à voir gratuitement en streaming. Cette liste est automatiquement mise à jour pour refléter les nouveaux ajouts des utilisateurs ou les retraits dans les cas de problèmes avec les droits. Les films viennent principalement de archive.org, mais aussi de YouTube, Dailymotion et Vimeo. Bon Visionnage !`
    return (
        <VideoList url={url} title={title} description={description} edges={edges} similar={
            <>
                <Link to="/collections/dessins-animes-gratuits/">Dessins animés gratuits</Link>, <Link to="/collections/documentaires-gratuits/">Documentaires gratuits</Link>
            </>
        }/>
    )
}

export default VieuxFilmsGratuits


  
export const query = graphql`
  query {
    allVideo(filter: {video_taken_down: {eq: false}, movie_genres: {nin: ["Animation"]}, movie_release: {lte: "2000"}}) {
        edges {
          node {
            video_id
            video_subtitles
            video_audio
            video_title
            translation_synopsis
            translation_slug
            translation_title
            video_taken_down
            translation_poster {
                url
            }
          }
        }
    }
  }
`